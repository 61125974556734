<template>
<div
style="
position: absolute;
top: 50px;
left: 0px;
width: 100%;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;

"
>

<div
style="

"
>

  <lottie-player src="https://assets4.lottiefiles.com/datafiles/Wv6eeBslW1APprw/data.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"  autoplay></lottie-player>

</div>

  <div
  style="
  width: 100%;
  margin-top: 80px;"
  ><h2>Auftrag Erledigt!</h2></div>

<div
    style="
    width: 100%;
    margin-top: 80px;"
>

  <v-btn
outlined
@click="gotoStart"
>
  Weiter
</v-btn></div>
</div>
</template>

<script>
export default {
  name: "Done",

  created() {



  },

  methods:{

    gotoStart(){
      this.$router.push({ name: 'Home' })
    }

  }


}
</script>

<style scoped>

</style>
