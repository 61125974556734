<template>
<div
style="
margin: 0px;
width: 100%;
"
>


    <div
    v-if="id == ''"
    >

    <v-card
    style="
    margin-bottom: 35px;
    background: rgba(211,211,211,0.25);
    width: 100%"

    v-for="item in data"
    :key="item.id"
    @click="id = item.id"
    >
        <v-card-title>{{item.name}}</v-card-title>

<v-card-text>
    {{item.note}}
</v-card-text>

    </v-card>

    </div>

    <div
        v-if="id != ''"
    >
        <!--
<div
style="
position: absolute;
right: 20px;
top: 10px;
"
>

    <v-btn
        fab
        small
        @click="id = ''"
        > <v-icon dark>
        mdi-close
    </v-icon></v-btn>

</div>

-->

        <div
        style="
        position: absolute;
        left: 15px;
        top: 10px;
        "
        ><h2><b>{{name}}</b></h2></div>

        <div
            style="
        position: absolute;
        left: 15px;
        top: 50px;
        width: auto;
        "
        >{{note}}</div>


        <div
            style="
        position: absolute;
        left: 15px;
        top: 150px;
        "
        >SOLL: <b>{{qty_soll}}</b> IST: <b>{{qty_ist}}</b></div>


        <!-- LISTE EINFÜGEN -->


        <table
            style="
    position: absolute;
    top: 200px;
    width: 100%"
        >
            <thead>
            <tr
                style="background: lightgray"
            >
                <th
                    style="width: 200px;"
                    class="text-center">
                    Name
                </th>
                <th
                    style="width: 100px;"
                    class="text-center">
                    SOLL
                </th>
                <th
                    style="width: 100px;"
                    class="text-center">
                    IST
                </th>

                <th
                    style="width: 150px;"
                    class="text-center">
                    Bemerkung
                </th>


            </tr>
            </thead>


            <tbody>

            <tr
                v-for="(item, index) in data_items"
                :key="item.id"

                :style="item.background_color"

            >

                <td
                    :id=" item.name"
                >{{ item.name }}</td>
                <td>
                {{item.qty_soll}}
                </td>
                <td>
                    <v-text-field
                        hide-details
                        outlined
                        type="number"
                        class="text-center"
                        v-model="item.qty_ist"
                        @change="updateItemQty(index)"
                        @update="updateItemQty(index)"
                        style="
                text-align: center;
                "



                    ></v-text-field>
                </td>
                <td>
                    <v-text-field
                        hide-details
                        outlined
                        v-model="item.note"
                        @change="updateItemNote(index)"
                        @update="updateItemNote(index)"
                    ></v-text-field>
                </td>

            </tr>

            </tbody>


        </table>

    </div>





</div>
</template>

<script>
export default {
    name: "cSammelliste",


    data () {
        return {

            title: 'Sammelliste',
            table: 'collecting',

            snackbar: false,
            snackbar_title: 'Gespeichert!',
            snackbar_color: 'red',
            timeout: 4000,

            componentKey: 0,

            dialog_delete: false,
            dialog_delete_text: '',
            dialog_delete_password: '',
            dialog_delete_id: '',

            data: [],
            data_items: [],


            name: '',
            note: '',

            qty_soll: '',
            qty_ist: '',

            id: '',

            status: 1,

        }
    },


    async created() {

        this.loadData()

    },

    watch:{

        id(){

            if(this.id != ""){

                this.loadSammelliste()

            }

        }



    },



    methods:{


        async loadSammelliste(){

            // Einstellungen
            var param = {
                table: "collecting",
                statement: "select",
                type: "detail",
                where: "true",
                selector: "id",
                value: this.id,
                short: "true", // Übermittlung nur von id
                // filter: "false",
                // filterfield: "",
                // filtervalue: "",
                // operator: "true",
                // Filter noch hinzufügen
                extension: "",
            }

            // Daten -> Werden nur bei Insert oder Update gesendet
            var data = {
            }

            var result = await this.$func.queryAPI(param, data)
            console.log(result)


            this.name = result[0]["name"]
            this.note = result[0]["note"]


            this.data_items = []


            // Einstellungen
            param = {
                table: "collecting_items",
                statement: "custom",
                type: "",
                where: "",
                selector: "",
                value: "",
                short: "false", // Übermittlung nur von id
                // filter: "false",
                // filterfield: "",
                // filtervalue: "",
                // operator: "true",
                // Filter noch hinzufügen
                extension: "SELECT * FROM `collecting_items` WHERE collecting_id='" + this.id + "' ORDER BY name ASC",
            }

            // Daten -> Werden nur bei Insert oder Update gesendet
            data = {
            }

            result = await this.$func.queryAPI(param, data)
            console.log(result)


            var background_color


            for (var i1 = 0; i1 < result.length; i1++) {


                this.qty_ist = Number(this.qty_ist) + Number(result[i1]["qty_ist"])
                this.qty_soll = Number(this.qty_soll) + Number(result[i1]["qty_soll"])

                if (Number(result[i1]["qty_ist"]) >= Number(result[i1]["qty_soll"])) {
                    background_color = "background: green;"
                } else {
                    background_color = "background: white;"
                    // background_color = "background: red;"
                }


                this.data_items.push({id: result[i1]["id"], name: result[i1]["name"], qty_soll: result[i1]["qty_soll"], qty_ist: result[i1]["qty_ist"], note: result[i1]["note"], background_color: background_color})

            }


            this.data_items.sort((a, b) => {
              const nameA = a.name;
              const nameB = b.name;

              const numA = nameA.includes('-') ? nameA.split('-').map(Number) : [parseInt(nameA, 10)];
              const numB = nameB.includes('-') ? nameB.split('-').map(Number) : [parseInt(nameB, 10)];

              const maxLength = Math.max(numA.length, numB.length);

              for (let i = maxLength - 1; i >= 0; i--) {
                const valA = numA[i] || 0;
                const valB = numB[i] || 0;

                if (valA !== valB) {
                  return valA - valB;
                }
              }

              return 0;
            });




        },



        async updateItemQty(id){
            console.log("updateItemQty")
            console.log(id)
            console.log(this.data_items[id].qty_ist)

            /* ToDo: Log erstellen */


            // Einstellungen
            var param = {
                table: "collecting_items",
                statement: "select",
                type: "detail",
                where: "true",
                selector: "id",
                value: this.data_items[id].id,
                short: "", // Übermittlung nur von id
                // filter: "false",
                // filterfield: "",
                // filtervalue: "",
                // operator: "true",
                // Filter noch hinzufügen
                extension: "",
            }

            // Daten -> Werden nur bei Insert oder Update gesendet
            var data = {
            }

            var result = await this.$func.queryAPI(param, data)
            console.log("qty_ist_before")
            console.log(result)

            var qty_ist_before = "0"
            qty_ist_before = result[0]["qty_ist"]



            // Einstellungen
            param = {
                table: "collecting_items",
                statement: "update",
                type: "",
                where: "true",
                selector: "id",
                value: this.data_items[id].id,
                short: "", // Übermittlung nur von id
                // filter: "false",
                // filterfield: "",
                // filtervalue: "",
                // operator: "true",
                // Filter noch hinzufügen
                extension: "",
            }

            // Daten -> Werden nur bei Insert oder Update gesendet
            data = {
                qty_ist: parseInt(this.data_items[id].qty_ist),
                qty_ist_before: qty_ist_before,
                // last_update: this.$moment(Date.now()).format('YYYY-MM-DD HH:MM:SS'),
                worker_edit: localStorage.username
            }

            result = await this.$func.queryAPI(param, data)
            console.log(result)

           // await this.loadSammelliste()


        },

        async updateItemNote(id){
            console.log("updateItemNote")
            console.log(id)
            console.log(this.data_items[id].qty)

            // Einstellungen
            var param = {
                table: "collecting_items",
                statement: "update",
                type: "",
                where: "true",
                selector: "id",
                value: this.data_items[id].id,
                short: "", // Übermittlung nur von id
                // filter: "false",
                // filterfield: "",
                // filtervalue: "",
                // operator: "true",
                // Filter noch hinzufügen
                extension: "",
            }

            // Daten -> Werden nur bei Insert oder Update gesendet
            var data = {
                note: this.data_items[id].note,
                // last_update: this.$moment(Date.now()).format('YYYY-MM-DD HH:MM:SS'),
                worker_edit: localStorage.username
            }

            var result = await this.$func.queryAPI(param, data)
            console.log(result)

            // await this.loadData()


        },



        async loadData(){

            this.data = []

            // Einstellungen
            var param = {
                table: "collecting",
                statement: "select",
                type: "detail",
                where: "true",
                selector: "status",
                value: "1",
                short: "true", // Übermittlung nur von id
                // filter: "false",
                // filterfield: "",
                // filtervalue: "",
                // operator: "true",
                // Filter noch hinzufügen
                extension: "",
            }

            // Daten -> Werden nur bei Insert oder Update gesendet
            var data = {
            }

            var result = await this.$func.queryAPI(param, data)
            console.log(result)

            this.data = result

        }


    }


}
</script>

<style scoped>

</style>
