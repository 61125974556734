<template>

        <v-layout
            style="margin: 0px;"
        >


            <div
                    style="
                    margin-top: 10px;
                    width: 100%;
                    text-align: center"
                    v-if="value== 0 && selected_quality==''"
            >

                <h2>Auswahl Qualität</h2><br><br>

                <v-btn

                        style="
                        background: greenyellow;
                        margin: 15px;
                        height: 60px;
                        width: 200px;"
                        outlined
                        @click="selected_quality='1'">EXTRA</v-btn><br>
                <v-btn
                        style="
                        background: orange;
                        margin: 15px;
                        height: 60px;
                        width: 200px;"
                        outlined
                        @click="selected_quality='2'">HOCH</v-btn><br>
                <v-btn
                        style="                        background: dodgerblue;
                        margin: 15px;
                        height: 60px;
                        width: 200px;"
                        outlined
                        @click="selected_quality='3'">ENGLAND</v-btn><br>

                <v-btn
                        style="
                        background: plum;
                        margin: 15px;
                        height: 60px;
                        width: 200px;"
                        outlined
                        @click="selected_quality='4'">NORMAL</v-btn><br>

                <v-btn
                    style="
                          background: #1f1910;
                          color: #FFFFFF;
                          margin: 15px;
                          height: 60px;
                          width: 200px;"
                    outlined
                    @click="selected_quality='5'">MONTAGE</v-btn><br>

            </div>


<div
        v-else
style="

position: absolute;
top: 0px;
bottom: 56px;
left: 0px;
width: 100%;
overflow-x:scroll;
"
>

            <c-lagerbestand
            v-if="value == 0"
            v-bind:quality="selected_quality"
            ></c-lagerbestand>

<div
v-if="reload"
>
            <c-sammelliste
            v-if="value == 1"
            ></c-sammelliste>
</div>
</div>


            <v-bottom-navigation
        grow
        active
        fixed
        color="pink"

        v-model="value"
    >
        <v-btn @click="selected_quality=''" value="0">
            <v-icon>mdi-garage-variant</v-icon>

            Lagerbestand
        </v-btn>

        <v-btn
                @click="forceRenderer()"
                value="1">
            <v-icon>mdi-clipboard-check-multiple-outline</v-icon>

            Sammelliste
        </v-btn>


    </v-bottom-navigation>




    </v-layout>



</template>

<script>
import CLagerbestand from "@/components/cLagerbestand.vue";
import CSammelliste from "@/components/cSammelliste.vue";

export default {
    name: "Stock",
    components: {CSammelliste, CLagerbestand},

    data () {

        return {

        value: 0,
        selected_quality: '',

        reload: true,

      }

   },


    methods:{

        async forceRenderer(){
            this.reload = false
            await this.$nextTick();
            this.reload = true
        }


    },

    watch: {
        // whenever question changes, this function will run
        value() {
        console.log("value: " + this.value)
        }
    },



}
</script>

<style scoped>

</style>
