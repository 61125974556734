<template>
  <v-card
      outlined
      elevation="7"
      style="
        margin: 5px;
        width: 90%;
        max-width: 350px;
        "

  >
    <v-card-title>{{article_name}}</v-card-title>


    <v-card-text>

      <v-row>

        <v-text-field
            v-model="order_qty"
            class="centered-input text--darken-3 mt-3"
            outlined
            disabled
            label="Angemeldet"
            type="number"
            :value="order_qty"
            style="
            text-align: center;
            width: 70px;"
        >

        </v-text-field>

        <v-text-field
            v-model="pickup_qty"
            class="centered-input text--darken-3 mt-3"
            outlined
            label="Abgeholt"
            type="number"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
            autocomplete="off"
            style="width: 70px;"
            @input="updateData"
        >

        </v-text-field>

      </v-row>

    </v-card-text>

      <div
      v-if="wasteno == '160103'"
      >
    Qualität <b>{{ rating_in_percent }}</b>

    <v-rating
        @input="updateData"
        v-model="rating">
      <template v-slot:item="props">
        <v-icon
            :color="props.isFilled ? genColor(props.index) : 'grey lighten-1'"
            large
            @click="props.click"
        >


          {{ genIcon(props.value) }}

        </v-icon>
      </template>
    </v-rating>

      </div>




  </v-card>
</template>

<script>
export default {
  name: "CardTaskItem",


  created() {
    this.loadData()
  },

  data () {

    return {

      snackbar: '',
      snackbar_title: '',
      snackbar_color: 'red',

      timeout: 1000,

      driver_id: localStorage.driver_id,

      colors: ['red', 'orange', 'yellow', 'lime', 'green'],
      rating: 0,
      rating_in_percent: '',

      article_name: '',
      order_qty: '',
      pickup_qty: '',
      price: '',
      weight: '',
      weight_total: '',
      wasteno:''


    }
  },


  methods: {

    async loadData() {
      console.log("loadData")
      console.log("task_items_id: " + this.task_items_id)

      // Einstellungen
      var param = {
        table: "task_items",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.task_items_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if(result.length == 0){
        return
      }


      if(result.length != 0){

        if (result["status"] == "404"){
          this.$router.push({ name: 'Error' })
        }

      }

      this.article_name = result[0]["article_name"]
      this.order_qty = result[0]["order_qty"]
      this.pickup_qty = result[0]["pickup_qty"]
      this.weight = result[0]["weight"]
      this.wasteno = result[0]["wasteno"]
      this.rating = Number(result[0]["rating"])

    },

    genColor (i) {
      return this.colors[i]
    },


    genIcon (i) {

      console.log(i)

      if(i == 0){
        return "mdi-emoticon-outline"
      }
      if(i == 5){
        return "mdi-emoticon-outline"
      }
      if(i == 4){
        return "mdi-emoticon-happy-outline"
      }
      if(i == 3){
        return "mdi-emoticon-neutral-outline"
      }
      if(i == 2){
        return "mdi-emoticon-sad-outline"
      }
      if(i == 1){
        return "mdi-emoticon-frown-outline"
      }

    },



    async updateData(){
      console.log("loadData")
      console.log("task_items_id: " + this.task_items_id)


      if(this.pickup_qty != ""){
        this.weight_total = Number(this.weight * Number(this.pickup_qty)).toFixed(2)
      }else{
        this.weight_total = 0
        this.pickup_qty = 0
      }


      // Einstellungen
      var param = {
        table: "task_items",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: this.task_items_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
        pickup_qty: this.pickup_qty,
        weight_total: this.weight_total,
        rating: this.rating,
        driver_id: this.driver_id,
        driver_name: localStorage.driver_name,
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if(result.length != 0){

        if (result["status"] == "404"){
          this.$router.push({ name: 'Error' })
        }

      }


    },




  },



  watch:{

    rating() {

      if (this.rating == "5"){
        this.rating_in_percent = "45%"
      }
      if (this.rating == "4"){
        this.rating_in_percent = "35%"
      }
      if (this.rating == "3"){
        this.rating_in_percent = "25%"
      }
      if (this.rating == "2"){
        this.rating_in_percent = "15%"
      }
      if (this.rating == "1"){
        this.rating_in_percent = "5%"
      }

    }



  },




  props: {

    task_items_id: {
      type: String,
      default: null,
    },


  }

}

</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
</style>
