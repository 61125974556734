<template>





  <v-layout
      align-center
      justify-center
  >

    <v-dialog
        v-model="dialog_add_items"
        persistent
        width="auto"
        style="z-index: 50"
    >

      <v-card>

        <v-card-title>NEUE ABHOLPOSITION</v-card-title>
        <v-card-text>
        <v-select
         label="Bitte auswählen:"
         v-model="selected_article"

        :items="list_article"
        ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" @click="dialog_add_items = false">ABBRECHEN</v-btn> <v-btn color="green" @click="addNewTaskItem">HINZUFÜGEN</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>



<v-container
    fluid
    style="
    position: absolute;
    top: 0px;
    left: 0px;
    "
>


<v-row>
    <v-card
        outlined
        elevation="7"
        style="
        margin: 5px;
        width: 90%;
        max-width: 350px;
"
    >

      <v-card-title><h5>{{company}}</h5></v-card-title>
      <v-card-text>
        <v-row style="text-align: left; padding-left: 14px;"><h3>{{street}}</h3></v-row>
        <v-row style="text-align: left; padding-left: 14px; padding-bottom: 15px;"><h3>{{zipcode}} {{city}}</h3></v-row>
        <v-row></v-row>
        <v-row></v-row>

        <v-row style="text-align: left; padding-left: 14px; padding-bottom: 15px;"><h3>Auftragsnummer:<br><b>{{document_no}}</b></h3></v-row>
        <v-row style="text-align: left; padding-left: 14px; padding-bottom: 15px;"><h3>Kundenuntergruppe:<br><b>{{group_type_text}}</b></h3></v-row>

      </v-card-text>

    </v-card>


  <v-card
      outlined
      elevation="7"
      style="
        margin: 5px;
        width: 90%;
        max-width: 350px;
"
  >
    <v-card-title>Bemerkungen</v-card-title>
    <v-card-text>
      <v-row style="text-align: left; padding-left: 14px;"><h3>{{note}}</h3></v-row>
    </v-card-text>

  </v-card>



  <CardTaskItem
      v-for="item in items"
      :key="item.id"
      v-bind:task_items_id="item.id"
  ></CardTaskItem>
  <div>
    <v-fab-transition>
      <v-btn
          style="
        margin-bottom: 50px;
        color: #FFFFFF"
          elevation="2"
          fab
          absolute
          small
          right

          color="pink"
          @click="dialog_add_items = true"
      > <v-icon>mdi-plus</v-icon></v-btn>
    </v-fab-transition>
  </div>



  <v-card
      outlined
      elevation="7"
      style="
        margin: 5px;
        width: 90%;
        max-width: 350px;
"
  >

    <v-card-title>Bilder</v-card-title>

    <v-card-text>
      <cImageUpload
          v-bind:value="task_id"
          v-bind:selector="'task_id'"
          v-bind:table="'images'"
      ></cImageUpload>
    </v-card-text>

  </v-card>




  <v-card
      outlined
      elevation="7"
      style="
        margin: 5px;
        width: 90%;
        max-width: 350px;
        height: 200px;
"
  >


    <v-card-text>

<v-btn
outlined
@click="gotoNext"
>Weiter</v-btn>

    </v-card-text>

  </v-card>



</v-row>





</v-container>




  </v-layout>






</template>

<script>

import CardTaskItem from '@/components/CardTaskItem'
import cImageUpload from '@/components/cImageUpload'


export default {
  name: "Detail",

  components: {
    CardTaskItem,
    cImageUpload,
  },

  data () {

    return {

      snackbar: '',
      snackbar_title: '',
      snackbar_color: 'red',

      timeout: 1000,

      dialog_add_items: false,


      driver_id: localStorage.driver_id,

      tour_id: '',
      temp_task_id: '',
      task_id: '',

      group_type_id: '',
      group_type_text: '',

      customer_id: '',
      document_no: '',
      company: '',
      street: '',
      zipcode: '',
      city: '',
      phone: '',
      note: '',

      items: [],

      list_article: [],
      selected_article: '',


    }
  },

  async created(){

    if(this.$route.params.id != undefined) {
      this.task_id = this.$route.params.id
    }else{
      this.$router.push({ name: 'Home' })
    }

    await this.loadArticle()

    await this.loadData()

  },

  methods:{


    async addNewTaskItem(){

      console.log("this.selected_article")
      console.log(this.selected_article)

      if(this.selected_article == ""){
        alert("Bitte Artikel auswählen!")
        return
      }

      // Parameter
      var param1 = {
        table: "article",
        statement: "select",
        type: "list",
        where: "true",
        selector: "name",
        value: this.selected_article,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Data
      var data1 = {

      }

      var result1 = await this.$func.queryAPI(param1, data1)
      console.log("result1")
      console.log(result1)




      // Parameter
      var param3 = {
        table: "group_price",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT * FROM group_price WHERE group_type_id='" + this.group_type_id + "' AND article_name='" + this.selected_article + "'",
      }

      console.log(param3.extension)

      // Data
      var data3 = {

      }

      var result3 = await this.$func.queryAPI(param3, data3)
      console.log("result3")
      console.log(result3)

      var price = 0

      if (result3.length != 0){
        price = result3[0]["price"]
      }






      // Parameter
      var param2 = {
        table: "task_items",
        statement: "insert",
        type: "",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Data
      var data2 = {

        article_id: result1[0]["id"],
        article_name: result1[0]["name"],
        tour_id: this.tour_id,
        task_id: this.task_id,
        temp_task_id: this.temp_task_id,
        order_qty: '0',
        price: price,
        weight: result1[0]["weight"] ?? 0,
        wasteno: result1[0]["wasteno"] ?? "",
        customer_id: this.customer_id,
        group_type_id: this.group_type_id,
      }


      var result2 = await this.$func.queryAPI(param2, data2)
      console.log(result2)


      this.dialog_add_items = false
      this.selected_article = ""

      location.reload()


    },



    async loadArticle(){
      console.log("loadArticle")

      // Einstellungen
      var param = {
        table: "article",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "ORDER BY name ASC",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if(result.length === 0){
        return
      }


      for (var i1 = 0; i1 < result.length; i1++) {
        this.list_article.push(result[i1]["name"])
      }

      console.log("this.list_article")
      console.log(this.list_article)


    },

    async loadData(){
      console.log("loadData")

      // Einstellungen
      var param = {
        table: "task",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if(result.length != 0){

        if (result["status"] == "404"){
          this.$router.push({ name: 'Error' })
        }

      }



      this.tour_id = result[0]["tour_id"]
      this.group_type_id = result[0]["group_type_id"]
      this.group_type_text = result[0]["group_type_text"]

      this.customer_id = result[0]["customer_id"]
      this.company = result[0]["company"]
      this.street = result[0]["street"]
      this.zipcode = result[0]["zipcode"]
      this.city = result[0]["city"]
      this.phone = result[0]["phone"]

      this.document_no = result[0]["document_no"]

      this.note = result[0]["note"]
      this.date = result[0]["planed_date"]

      this.order_qty = result[0]["order_qty"]
      this.article_name = result[0]["article_name"]

      this.temp_task_id = result[0]["temp_task_id"]


      // Einstellungen
      param = {
        table: "task_items",
        statement: "select",
        type: "list",
        where: "true",
        selector: "temp_task_id",
        value: this.temp_task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
       data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log(result)

      if(result.length != 0){

        if (result["status"] == "404"){
          this.$router.push({ name: 'Error' })
        }

      }


      this.items = []

      for (var i1 = 0; i1 < result.length; i1++) {

        console.log("wasteno: " + result[i1]["wasteno"])

        if (result[i1]["wasteno"] == "" || result[i1]["wasteno"] == null){
          result[i1]["wasteno"] = await this.setWasteNo(result[i1]["id"], result[i1]["article_name"])
        }

        this.items.push({id:  result[i1]["id"], wasteno: result[i1]["wasteno"], order_qty: result[i1]["order_qty"], article_name: result[i1]["article_name"]})
      }

      console.log("items")
      console.log(this.items)

    },

    async checkValidation(){
      console.log("checkValidation")

      // Einstellungen
      var param = {
        table: "task_items",
        statement: "select",
        type: "list",
        where: "true",
        selector: "temp_task_id",
        value: this.temp_task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if(result.length != 0){

        if (result["status"] == "404"){
          this.$router.push({ name: 'Error' })
        }

      }


      for (var i1 = 0; i1 < result.length; i1++) {

        console.log("pickup_qty")
        console.log(result[i1]["pickup_qty"])


        if (result[i1]["pickup_qty"] == "" || result[i1]["pickup_qty"] == null){
          alert("Bitte Stückzahl eingeben!")
          return

        }

        if(result[i1]["wasteno"] == '160103'){
        if (result[i1]["rating"] == "0" || result[i1]["rating"] == null){
          alert("Bitte Bewertung eingeben!")
          return
        }
        }

      }

      this.$router.push({ name: 'Confirmation', params:{id: this.task_id} })


    },


    async setWasteNo(task_items_id, article_name){
        console.log("setWasteNo")

      // Einstellungen
      var param1 = {
        table: "article",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "name",
        value: article_name,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {
      }

      var result1 = await this.$func.queryAPI(param1, data1)
      console.log("result1")
      console.log(result1)


      // Einstellungen
      var param2 = {
        table: "task_items",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: task_items_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {
        wasteno: result1[0]["wasteno"]
      }

      var result2 = await this.$func.queryAPI(param2, data2)
      console.log("result2")
      console.log(result2)


      return result1["wasteno"]



    },


    gotoNext(){
      console.log("gotoNext")

      this.checkValidation()

    }



  }

}
</script>

<style scoped>


.centered-input >>> input {
  text-align: center
}


</style>
