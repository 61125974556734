<template>
    <div
        style="
width: 100%;
display: flex;
justify-content: center;
"

    >
        <div

                style="
                position: absolute;
                top: 5px;
                "
        >

          <v-select
              label="REIFEN TYP"
              :items="typ_items"
              item-text="name"
              item-value="value"
              v-model="selected_typ"
              style="
        display: table;
        margin: 0 auto;
        width: 250px;"
          >

          </v-select>


            <v-autocomplete
                    v-model="select_filter"
                    clearable
                    label="Schnellsuche"
                    :items="data_items"
                    style="display: inline-block"
            ></v-autocomplete>



            <v-btn
                    style="
        display: inline-block;
        margin-left: 10px;
        "
                    outlined
                    @click="reloadList()"
            >LISTE NEU LADEN</v-btn>
        </div>


        <table

                style="
    position: absolute;
    top: 190px;
    width: 100%"
        >
            <thead>
            <tr
                    style="background: lightgray"
            >
                <th
                        style="width: 200px;"
                        class="text-center">
                    Name
                </th>
                <th
                        style="width: 60px;"
                        class="text-center">
                    {{fieldname}}
                </th>
                <th
                    style="width: 120px;"
                    class="text-center">
                    Bemerkung
                </th>



            </tr>
            </thead>


            <tbody>

            <tr
                    v-for="(item, index) in data"
                    :key="item.id"
            >
                <td
                        :id=" item.name"
                >{{ item.name }}</td>
                <td>
                    <v-text-field
                        v-if="selected_typ == 1"
                        outlined
                        type="number"
                        class="text-center"
                        v-model="item.qty1"
                        @change="updateItemQty(index)"
                        @update="updateItemQty(index)"
                        style="
                text-align: center;
                "
                        :color="background"
                    ></v-text-field>
                    <v-text-field
                        v-if="selected_typ == 2"
                        outlined
                        type="number"
                        class="text-center"
                        v-model="item.qty2"
                        @change="updateItemQty(index)"
                        @update="updateItemQty(index)"
                        style="
                text-align: center;
                "
                        :color="background"
                    ></v-text-field>
                    <v-text-field
                        v-if="selected_typ == 3"
                        outlined
                        type="number"
                        class="text-center"
                        v-model="item.qty3"
                        @change="updateItemQty(index)"
                        @update="updateItemQty(index)"
                        style="
                text-align: center;
                "
                        :color="background"
                    ></v-text-field>
                    <v-text-field
                        v-if="selected_typ == 4"
                        outlined
                        type="number"
                        class="text-center"
                        v-model="item.qty4"
                        @change="updateItemQty(index)"
                        @update="updateItemQty(index)"
                        style="
                text-align: center;
                "
                        :color="background"
                    ></v-text-field>
                </td>
                <td>
                    <v-text-field
                        v-if="selected_typ == 1"
                        outlined
                        type="text"
                        class="text-center"
                        v-model="item.qty1_note"
                        @change="updateItemQty(index)"
                        @update="updateItemQty(index)"
                        style="
                text-align: center;
                "
                    ></v-text-field>
                    <v-text-field
                        v-if="selected_typ == 2"
                        outlined
                        type="text"
                        class="text-center"
                        v-model="item.qty2_note"
                        @change="updateItemQty(index)"
                        @update="updateItemQty(index)"
                        style="
                text-align: center;
                "
                    ></v-text-field>
                    <v-text-field
                        v-if="selected_typ == 3"
                        outlined
                        type="text"
                        class="text-center"
                        v-model="item.qty3_note"
                        @change="updateItemQty(index)"
                        @update="updateItemQty(index)"
                        style="
                text-align: center;
                "
                    ></v-text-field>
                    <v-text-field
                        v-if="selected_typ == 4"
                        outlined
                        type="text"
                        class="text-center"
                        v-model="item.qty4_note"
                        @change="updateItemQty(index)"
                        @update="updateItemQty(index)"
                        style="
                text-align: center;
                "
                    ></v-text-field>
                </td>

            </tr>

            </tbody>


        </table>

    </div>
</template>

<script>
export default {
    name: "cLagerbestand",

  mounted() {

      this.selected_typ = 1

  },

  data () {
        return {

            title: 'Sammelliste',
            table: 'group_tires',

            snackbar: false,
            snackbar_title: 'Gespeichert!',
            snackbar_color: 'red',
            timeout: 4000,

            data: [],
            data_items: [],

            fieldname: '',
            background: 'red',

            selected_typ: 1,
            select_filter: '',

          typ_items: [
            { name: 'SOMMER/EINZELN', value: 1 },
            { name: 'SOMMER/PAARE', value: 2 },
            { name: 'WINTER/EINZELN', value: 3 },
            { name: 'WINTER/PAARE', value: 4 }
                    ]
        }
    },

    created() {

        switch (this.quality) {

            case '1':
                this.fieldname = "EXTRA"
                this.background = "#ADFF2F"
                break;
            case '2':
                this.fieldname = "HOCH"
                this.background = "#FFA500"
                break;

            case '3':
                this.fieldname = "ENGLAND"
                this.background = "#1E90FF"
                break;

            case '4':
                this.fieldname = "NORMAL"
                this.background = "#DDA0DD"
                break;

            case '5':
              this.fieldname = "MONTAGE"
              this.background = "#DDA0DD"
              break;
        }

        this.loadData()

    },

    watch:{

        select_filter() {

            if(this.select_filter != "" && this.select_filter != null){
                document.getElementById(this.select_filter).scrollIntoView();
                document.getElementById(this.select_filter).style.backgroundColor = "yellow";
            }

        },


      selected_typ(){

          console.log("this.selected_typ")
          console.log(this.selected_typ)

      }

    },


    methods:{

        reloadList(){
            this.loadData()
        },

        async updateItemQty(id){
            console.log("updateItemQty")
            console.log(id)
            console.log(this.data[id].qty)
            console.log(this.typ)

          var table = "group_tires" + "_" + this.fieldname.toLocaleLowerCase()

            // Einstellungen
            var param = {
                table: table,
                statement: "update",
                type: "",
                where: "true",
                selector: "id",
                value: this.data[id].id,
                short: "", // Übermittlung nur von id
                // filter: "false",
                // filterfield: "",
                // filtervalue: "",
                // operator: "true",
                // Filter noch hinzufügen
                extension: "",
            }

            // Daten -> Werden nur bei Insert oder Update gesendet
            var data = {
                qty1: this.data[id].qty1,
                qty2: this.data[id].qty2,
                qty3: this.data[id].qty3,
                qty4: this.data[id].qty4,
                qty1_note: this.data[id].qty1_note,
                qty2_note: this.data[id].qty2_note,
                qty3_note: this.data[id].qty3_note,
                qty4_note: this.data[id].qty4_note,
                // last_update: this.$moment(Date.now()).format('YYYY-MM-DD HH:MM:SS'),
                worker_edit: localStorage.username
            }

            var result = await this.$func.queryAPI(param, data)
            console.log(result)

            await this.loadData()

        },

        async loadData(){
          console.log("loadData")

            this.data = []

            var table = "group_tires" + "_" + this.fieldname.toString().toLowerCase()


            // Einstellungen
            var param = {
                table: table,
                statement: "select",
                type: "list",
                where: "false",
                selector: "",
                value: "",
                short: "", // Übermittlung nur von id
                // filter: "false",
                // filterfield: "",
                // filtervalue: "",
                // operator: "true",
                // Filter noch hinzufügen
                extension: "ORDER BY name ASC",
            }

            // Daten -> Werden nur bei Insert oder Update gesendet
            var data = {
            }

            var result = await this.$func.queryAPI(param, data)
            console.log(result)

            this.data = result.sort((a, b) => {
              const nameA = a.name;
              const nameB = b.name;

              const numA = nameA.includes('-') ? nameA.split('-').map(Number) : [parseInt(nameA, 10)];
              const numB = nameB.includes('-') ? nameB.split('-').map(Number) : [parseInt(nameB, 10)];

              const maxLength = Math.max(numA.length, numB.length);

              for (let i = maxLength - 1; i >= 0; i--) {
                const valA = numA[i] || 0;
                const valB = numB[i] || 0;

                if (valA !== valB) {
                  return valA - valB;
                }
              }

              return 0;
            });


            for (var i1 = 0; i1 < result.length; i1++) {
                this.data_items.push(result[i1]["name"])
            }



          this.data_items.sort((a, b) => {
            const numA = a.includes('-') ? a.split('-').map(Number) : [parseInt(a, 10)];
            const numB = b.includes('-') ? b.split('-').map(Number) : [parseInt(b, 10)];

            const maxLength = Math.max(numA.length, numB.length);

            for (let i = maxLength - 1; i >= 0; i--) {
              const valA = numA[i] || 0; // Verwende 0, wenn das Teil nicht existiert
              const valB = numB[i] || 0;

              if (valA !== valB) {
                return valA - valB;
              }
            }

            return 0; // Wenn alle Teile gleich sind, bleibt die Reihenfolge unverändert
          });


        }


    },


    props:{

        quality: String, // EXTRA, NORMAL

    }


}
</script>

<style scoped>

</style>
