<template>
  <div style="width: 100%">
    <br>
    <p>{{title}}</p>

    <template v-for="id in List">
      <cImage
          v-if="renderComponent"
          :key="id"
          v-bind:id="id"
          v-bind:table="table"
      ></cImage>
    </template>

    <div class="imageupload_addnew" v-on:click="btnAdd()">+</div>

    <input type="file" name="files" id="files" v-on:change="Upload()" style="display: none;" multiple>

  </div>
</template>

<script>

import Vue from "vue";
import cImage from "@/components/cImage";

export default {
  name: "cImageUpload",

  components:{
    cImage
  },


  data() {
    return {

      List: [],
      title: '',
      renderComponent: true,

      param: '',

    }

  },



  created() {
    // Prüfen ob Bilder zum Auftrag abgespeichert wurden wenn ja laden
    console.log("cImageUpload")
    console.log(this.value)

    this.param = "statement=select&table=" + this.table  + "&type=list&where=true&selector=" + this.selector + "&value=" + this.value + ""

  },

  async mounted() {
    console.log("cImageUpload")

    await this.checkImages()

    setInterval(this.checkImages, 1000)
  },


  methods:{


    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },


    btnAdd: function () {
      console.log("btnAdd")
      document.getElementById('files').click();
    },


    async checkImages(){
      // console.log ("checkImages")
      // console.log(Vue.prototype.$api_endpoint  + "?" + this.param)

      await this.$axios
          .get(Vue.prototype.$api_endpoint  + "?" + this.param)
          .then(response => (this.responseText = response))

      // console.log(this.responseText["data"])

      var i = 0

      // console.log(this.responseText["data"].length)
      // console.log(this.List.length)

      console.log(this.responseText["data"])


      if(this.responseText["data"].length != this.List.length){
        this.List = []

       // console.log(this.responseText["data"])

        for(i in this.responseText["data"]) {
          this.List.push(this.responseText["data"][i]["id"])
         // console.log("Image" + this.responseText["data"][i]["id"])
        }


        this.forceRerender()
      }
    },


    wait(ms){
      var start = new Date().getTime();
      var end = start;
      while(end < start + ms) {
        end = new Date().getTime();
      }
    },


    async Upload () {

      var totalfiles = document.getElementById('files').files.length;

      if(totalfiles > 0 ){

        var formData = new FormData();

        // Read selected files
        for (var index = 0; index < totalfiles; index++) {

          console.log("Files:")
          console.log(document.getElementById('files').files[index])


          formData.append("files[]", document.getElementById('files').files[index]);
        }

        formData.append("description", this.description)
        formData.append("type", this.type)
        formData.append("selector", this.selector)
        formData.append("value", this.value)
        formData.append("table", this.table)

        var xhttp = new XMLHttpRequest();

        // Set POST method and ajax file path
        xhttp.open("POST", Vue.prototype.$api_endpoint, true);

        // call on request changes state
        xhttp.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {

            var response = this.responseText;

            console.log("response")
            console.log(response);

            // this.Reload

          }
        };

        console.log(formData)

        // Send request with data
        await xhttp.send(formData);

        setTimeout(function(){
          this.Reload;
          console.log("response-send")
        }, 3000);



      }else{
        // alert("Please select a file");
      }

    }

  },


  props: {

    table: {
      type: String,
      default: null,
    },

    selector: {
      type: String,
      default: null,
    },

    value: {
      type: String,
      default: null,
    },

    type: {
      type: String,
      default: null,
    },

    description: {
      type: String,
      default: null,
    },

  }




}
</script>

<style scoped>

.imageupload_addnew{

  float: left;

  margin: 20px;
  margin-left: 0px;

  width: 150px;
  height: 110px;
  background-color: lightgray;
  opacity: 0.6;
  font-size: 40px;
  cursor: pointer;

  text-align: center;
  vertical-align: middle;
  line-height: 110px;       /* The same as your div height */
}
</style>
