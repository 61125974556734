<template>

  <v-layout
      align-center
      justify-center
  >

    <v-card
      outlined
      class="elevation-12"
      style="
      max-width: 350px;
      width: 95%"
    >

      <v-img
          align-center
          justify-center

          :lazy-src="this.header_logo"
          :src="this.header_logo"
      >
      </v-img>
      <v-card-text>
        <v-card-title>Login - v.1.0.7</v-card-title>
        <v-form>

          <!--
          <v-text-field
              v-model="username"
              label="Login"
              type="text"
          ></v-text-field>
          -->

          <CustomSelect
              v-model="driver"
              v-bind:table="'driver'"
              v-bind:field_text="'name'"
              v-bind:field_value="'id'"
              v-bind:label="'Benutzer'"
          >

          </CustomSelect>


          <v-text-field
              v-model="password"
              label="Password"
              type="password"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="btnLogin">Login</v-btn>
      </v-card-actions>
    </v-card>



    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbar_color"

    >
      {{ snackbar_title }}
      <!--
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
       -->

    </v-snackbar>




  </v-layout>

</template>

<script>

import CustomSelect from "@/components/CustomSelect";
import Vue from "vue";


export default {
  name: "Login",

  components:{
    CustomSelect,
  },

  data () {
    return {

      snackbar: '',
      snackbar_title: '',
      snackbar_color: 'red',

      timeout: 1000,

      header_logo: Vue.prototype.$header_logo,

      driver: '',
      driver_id: '',
      driver_name: '',
      password: '',

    }
  },

  async created() {

    // Falls vorhanden eingebuchte Tour wird gelöscht
    await this.resetTour()

    localStorage.driver_name = ""
    localStorage.driver_id = ""
    localStorage.tour_id = ""

  },

  methods: {

   async resetTour(){

     if(localStorage.driver_id == "" || localStorage.driver_id == undefined){
       return
     }

     // Einstellungen
     var param = {
       table: "driver",
       statement: "update",
       type: "",
       where: "true",
       selector: "id",
       value: localStorage.driver_id,
       short: "true", // Übermittlung nur von id
       // filter: "false",
       // filterfield: "",
       // filtervalue: "",
       // operator: "true",
       // Filter noch hinzufügen
       extension: "",
     }

     // Daten -> Werden nur bei Insert oder Update gesendet
     var data = {
       tour_id: '',
     }

     var result = await this.$func.queryAPI(param, data)
     console.log(result)


   },




   async btnLogin(){
      console.log("Login")

      if(this.driver.trim() == ";"){
        this.snackbar_title = "Benutzername eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }
      if(this.password == ""){
        this.snackbar_title = "Passwort eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }


      console.log("this.driver_id: " + this.driver_id)


      // Einstellungen
      var param = {
        table: "driver",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.driver_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

     if(result.length != 0){

       if (result["status"] == "404"){
         this.$router.push({ name: 'Error' })
       }

     }



      if(result.length == 0){
        this.snackbar_title = "Benutzer nicht vorhanden!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      if (result[0]["password"] != this.password){
        this.snackbar_title = "Falsches Passwort!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      console.log("result[0][\"name\"]" + result[0]["name"])

      localStorage.driver_name = result[0]["name"]
      localStorage.driver_id = result[0]["id"]

      localStorage.username = result[0]["name"]

      this.$router.push({ name: 'ModeSelect' })

    },


  },

  watch: {


    driver: function () {

      console.log(this.driver)

      if (this.driver.trim() != ";" && this.driver.includes(";") == true) {
        this.driver_id = this.driver.split(";")[1]
        this.driver_name = this.driver.split(";")[0]
      }

    },

  }


}
</script>

<style scoped>

</style>
