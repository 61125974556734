<template>
  <div
  >
    <!--:hint="`${select.text} ${select.value}`"-->
    <v-select

        outlined
        v-model="select"

        :items="items"
        item-text="text"
        item-value="value"
        :label="label"
        persistent-hint
        return-object
        clearable

        @input="handleInput"

    ></v-select>




  </div>
</template>

<script>
export default {
  name: "CustomSelect",

  data () {
    return {
      items: [],
      select: {text: '', value: ''},

      content: this.value
    }
  },

  created() {

    this.loadData()

    console.log("created")
    console.log(this.select["text"] + ";" + this.select["value"])
    this.$emit('input', this.select["text"] + ";" + this.select["value"])

  },


  methods:{

    handleInput () {

      console.log("handleInput")

      if(this.select == null){
        console.log("" + ";" + "")
        this.$emit('input', "" + ";" + "")
      }else{
        console.log(this.select["text"] + ";" + this.select["value"])
        this.$emit('input', this.select["text"] + ";" + this.select["value"])
      }

    },


    async loadData(){

      this.data = []

      // Einstellungen
      var param = {
        table: this.table,
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      var data = {}
      var result = await this.$func.queryAPI(param, data)

      this.items = []

      for (var i1 = 0; i1 < result.length; i1++) {

        this.items[i1] = {text: result[i1][this.field_text], value: result[i1][this.field_value]}

        // Select Eintrag
        if(this.selected == result[i1][this.field_value]){
          this.select = {text: result[i1][this.field_text], value: result[i1][this.field_value]}
        }

      }

    },

  },


  props:{

    value: String,

    table: String,

    label: {
      type: String,
      default: 'Bitte auswählen!'
    },

    selected: {
      type: String,
      default: ''
    },

    field_text: String,
    field_value: String,
    // show_value: Boolean

  },


  model: {
    prop: 'value',
    event: 'input'
  },

}
</script>

<style scoped>

</style>
