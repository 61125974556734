import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

import VueAxios from './plugins/axios'

import {func} from './plugins/func'

import Moment from 'vue-moment'
Vue.use(Moment);

import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);

Vue.prototype.$func = func

// Globale Variablen
// Vue.prototype.$api_endpoint = 'http://localhost/reifen/api.php'

/*
Vue.prototype.$header_title = 'Reifen Hüska'
Vue.prototype.$header_color = '#383083'
Vue.prototype.$header_logo = '@/assets/images/logo_hueska.png'

Vue.prototype.$api_endpoint = 'https://api.reifen-hueska.de/api/api.php'
Vue.prototype.$api_pdf_endpoint = 'https://api.reifen-hueska.de/create_pdf.php'
Vue.prototype.$api_upload = 'https://api.reifen-hueska.de/upload/'
Vue.prototype.$api_hostname = window.location.host

Vue.prototype.$api_endpoint = 'http://localhost/reifen/api.php'
*/



if (window.location.hostname.includes("reifen-hueska.de") || window.location.hostname.includes("app-ionos.space")){
  Vue.prototype.$header_title = 'Reifen Hüska'
  Vue.prototype.$header_color = '#383083'
  Vue.prototype.$header_logo = '@/assets/images/logo_hueska.png'

  Vue.prototype.$api_endpoint = 'https://api.reifen-hueska.de/api/api.php'
  Vue.prototype.$api_pdf_endpoint = 'https://api.reifen-hueska.de/create_pdf.php'
  Vue.prototype.$api_upload = 'https://api.reifen-hueska.de/upload/'
  Vue.prototype.$api_hostname = window.location.host
}else if (window.location.hostname.includes("reifen-muka.de")) {
  Vue.prototype.$header_title = 'Reifen Muka'
  Vue.prototype.$header_color = '#0826e3'
  Vue.prototype.$header_logo = '@/assets/images/logo_muka.png'

  Vue.prototype.$api_endpoint = 'https://www.reifen-muka.de/api/api.php'
  Vue.prototype.$api_pdf_endpoint = 'https://www.reifen-muka.de/api/create_pdf.php'
  Vue.prototype.$api_upload = 'https://www.reifen-muka.de/api/upload/'
  Vue.prototype.$api_hostname = window.location.host
}else if (window.location.hostname.includes("localhost")) {
  Vue.prototype.$header_title = 'Reifen Hüska'
  Vue.prototype.$header_color = '#383083'
  Vue.prototype.$header_logo = '@/assets/images/logo_hueska.png'

  Vue.prototype.$api_endpoint = 'http://localhost/reifen/api.php'
  Vue.prototype.$api_pdf_endpoint = 'http://localhost/reifen/create_pdf.php'
  Vue.prototype.$api_upload = 'http://localhost/reifen/upload/'
  Vue.prototype.$api_hostname = window.location.host
}else{
  Vue.prototype.$header_title = 'Reifen Hüska'
  Vue.prototype.$header_color = '#383083'
  Vue.prototype.$header_logo = '@/assets/images/logo_hueska.png'

  Vue.prototype.$api_endpoint = 'http://192.168.178.125/reifen/api.php'
  Vue.prototype.$api_pdf_endpoint = 'http://192.168.178.125/reifen/create_pdf.php'
  Vue.prototype.$api_upload = 'http://192.168.178.125/reifen/upload/'
  Vue.prototype.$api_hostname = window.location.host
}




// Axios
Vue.use(VueAxios);



Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
