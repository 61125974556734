<template>
  <v-app>

    <v-app-bar
        elevation="4"
        :color="header_color"
        app
    >
      <v-app-bar-title
          style="
          color: white;"
      >{{welcome_title}}</v-app-bar-title>



      <v-spacer></v-spacer>

      <v-btn
          v-if="btn_logout"
          icon
          @click="btnLogout"
      >
        <v-icon
            style="color: white;"
        >mdi-logout-variant</v-icon>
      </v-btn>

      <v-btn
          v-if="btn_close"
          icon
          @click="btnClose"
      >
        <v-icon
            style="color: white;"
        >mdi-close</v-icon>
      </v-btn>


    </v-app-bar>

    <v-main
        style="
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        "
        class="fill-height" fluid
    >


      <v-container
              style="padding: 0px;"
              class="fill-height" fluid
      >
        <router-view />
      </v-container>

    </v-main>



    </v-app>

</template>

<script>
import Vue from "vue";

export default {
  name: "App",


  data () {
    return {

      snackbar: '',
      snackbar_title: '',
      snackbar_color: 'red',

      timeout: 1000,

      driver: '',
      driver_id: localStorage.driver_id,
      driver_name: localStorage.driver_name,
      password: '',
      welcome_title: '',

      header_title: Vue.prototype.$header_title,
      header_color: Vue.prototype.$header_color,

      btn_logout: true,
      btn_close: false,

    }
  },


  created() {

    // localStorage.username_name = ""

    if(this.$route.name != "Login"){

      console.log("localStorage.driver_name: " + localStorage.driver_name)

      if(localStorage.driver_name == ""  || localStorage.driver_name == undefined){
        this.$router.push({ name: 'Login' })
        return
      }

      this.welcome_title = "Hallo " + localStorage.driver_name
    }

    this.checkRoute()


    setInterval(this.checkTourExist, 60000);

  },

  watch:{
    $route (){

    this.checkRoute()

    },

  },


  methods:{

    async checkTourExist(){

      if(localStorage.tour_id == "" || localStorage.tour_id == undefined){
        return
      }

      if(localStorage.tour_setdate != this.$moment(Date.now()).format("DD.MM.YYYY")){
        localStorage.tour_id = ""
        localStorage.tour_setdate = ""
        this.$router.push({name: 'Login'})
        return
      }

      // console.log("checkTourExist")

      // Einstellungen
      var param = {
        table: "tour",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: localStorage.tour_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log (result)

      if (result.length == 0){
        localStorage.tour_id = ""
        this.$router.push({name: 'Login'})
      }



    },

    checkRoute(){


      if(localStorage.driver_name != ""){
        this.welcome_title = "Hallo " + localStorage.driver_name
      }

      if(this.$route.name == "Login"){
        this.welcome_title = ""
      }

      if(this.$route.name == "Detail" || this.$route.name == "Confirmation" || this.$route.name == "Signature" || this.$route.name == "Done"){
        this.btn_close = true
        this.btn_logout = false
      }else{
        this.btn_close = false
        this.btn_logout = true
      }


      if(this.$route.name == "Signature"){
        this.welcome_title = "Unterschrift"
      }


      if(localStorage.driver_id != ""){
        this.setLastActivity()
      }

    },

    btnClose(){
      console.log("btnLogout")
      if (this.$route.name != "Home") {
        this.$router.push({name: 'Home'})
      }
    },

    btnLogout() {
      console.log("btnLogout")
      if (this.$route.name != "Login") {
        this.$router.push({name: 'Login'})
      }
    },


   async setLastActivity(){

      // last_activity

      // Parameter
      var param = {
        table: "driver",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: localStorage.driver_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      var data = {

        last_activity: this.$moment(this.date).format("DD.MM.YYYY HH:mm:ss")

      }


      console.log(data)

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


    }




  }


}
</script>





<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.v-app-bar-title__content{
  width: 200px;
}

</style>
