<template>
  <v-layout
      align-center
      justify-center
      wrap
  >


    <v-flex
        style="margin-top: 100px;"
        class="text-center"
    >

  <v-img
      class="mx-auto"
      width="200px"

      :lazy-src="require('@/assets/images/disconnect.png')"
      :src="require('@/assets/images/disconnect.png')"
  >
  </v-img>
-

    </v-flex>


    <v-container
        fluid
    >Keine Internet Verbindung! </v-container>

    <v-flex
        style="margin-top: 30px;"
        class="text-center"
    >
    <v-btn
    @click="checkInternetConnection()"
    >Erneut Verbinden</v-btn>
    </v-flex>

</v-layout>
</template>

<script>
export default {
  name: "Error",


  methods:{

    async checkInternetConnection(){
      console.log("checkInternetConnection")


      if (await this.$func.queryAPITest() == "OK")
      {
        this.$router.go(-1)
      }

    }

  }


}
</script>

<style scoped>

</style>
