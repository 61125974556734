<template>
  <v-card
      outlined
      elevation="7"
      style="
        margin: 5px;
        width: 90%;
        max-width: 350px;
        "

  >
    <v-card-title>{{article_name}}</v-card-title>


    <v-card-text>

      <v-row>

        <v-text-field
            v-model="order_qty"
            class="centered-input text--darken-3 mt-3"
            outlined
            disabled
            label="Angemeldet"
            typ="number"
            :value="order_qty"
            style="
            text-align: center;
            width: 70px;"
        >

        </v-text-field>

        <v-text-field
            v-model="pickup_qty"
            class="centered-input text--darken-3 mt-3"
            outlined
            readonly
            label="Abgeholt"
            typ="number"
            style="width: 70px;"
        >

        </v-text-field>

      </v-row>

    </v-card-text>


  </v-card>
</template>

<script>
export default {
  name: "CardTaskItem",


  created() {
    this.loadData()
  },

  data () {

    return {

      snackbar: '',
      snackbar_title: '',
      snackbar_color: 'red',

      timeout: 1000,

      driver_id: localStorage.driver_id,

      colors: ['red', 'orange', 'yellow', 'lime', 'green'],
      rating: 0,
      rating_in_percent: '',

      article_name: '',
      order_qty: '',
      pickup_qty: '',


    }
  },


  methods: {

    async loadData() {
      console.log("loadData")
      console.log("task_items_id: " + this.task_items_id)

      // Einstellungen
      var param = {
        table: "task_items",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.task_items_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if(result.length == 0){
        return
      }

      this.article_name = result[0]["article_name"]
      this.order_qty = result[0]["order_qty"]
      this.pickup_qty = result[0]["pickup_qty"]

    },





  },



  watch:{


  },




  props: {

    task_items_id: {
      type: String,
      default: null,
    },


  }

}

</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
</style>
