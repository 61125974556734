<template>
<div
style="
width: 100%;
"
>

<!--
    <v-img
            align-center
            justify-center

            lazy-src="@/assets/images/1261055.png"
            src="@/assets/images/1261055.png"
    >
    </v-img>
-->

    <div>Bitte Modus auswählen:</div>


    <div
  style="
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  "

    >

    <div
    style="
    float: left;
    cursor: pointer;
    "

    class="select_box"

    @click="gotoStock()"
    >

        <img src="@/assets/images/1261055.png" width="80" height="80" alt="">

        <div>Lager</div>

    </div>

    <div
    style="
    float: left;
    cursor: pointer;
    "
    class="select_box"

    @click="gotoHome()"
    >

        <img src="@/assets/images/664468.png" width="80" height="80" alt="">

        <div>Abholung</div>

    </div>


    </div>



</div>
</template>

<script>
export default {
    name: "ModeSelect",

    methods:{

        gotoStock(){
            this.$router.push({ name: 'Stock' })
        },

        gotoHome(){
            this.$router.push({ name: 'Home' })
        }

    }

}
</script>

<style lang="scss" scoped>
/* Rectangle 1 */


.select_box{
box-sizing: border-box;

width: 150px;
height: 150px;

background: #FFFFFF;
border: 2px solid #000000;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;

margin: 10px;
padding: 20px;
}



</style>
